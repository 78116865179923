import styled from "styled-components";
import { device } from "../../constants/sizes";
import { ReactComponent as FlowerRedIcon } from "../Icons/FlowerRedIcon.svg";
import { ReactComponent as FlowerBlackIcon } from "../Icons/FlowerBlackIcon.svg";
import { ReactComponent as ArtistDrawingIcon } from "../Icons/ArtistDrawingIcon.svg";

export const BlackCircle = styled("div")`
  position: relative;
  top: -140px;
  left: 70px;
  width: 80px;
  height: 80px;
  background-color: black;
  border-radius: 50%;
  z-index: -1;
  cursor: pointer;
`;

export const BlackFlower = styled(FlowerBlackIcon)`
  position: relative;
  width: 200px;
  height: auto;
`;

export const RedCircle = styled("div")`
  position: relative;
  top: -180px;
  width: 130px;
  height: 130px;
  left: 40px;
  background-color: #ce0808;
  border-radius: 50%;
  z-index: -1;
`;

export const RedFlower = styled(FlowerRedIcon)`
  width: 200px;
  height: auto;
  margin-top: 200px;
  cursor: pointer;
`;

export const Section = styled("div")`
  font-family: "Archivo";
  width: 90%;
  margin: 0 auto;
  @media (max-width: 536px) {
    margin: 100px auto 0;
    width: auto;
  }
`;

export const Title = styled("div")`
  font-weight: 800;
  font-size: 64px;
  margin-top: 160px;
  @media (max-width: 536px) {
    width: 90%;
    margin: 0 auto;
  }
`;

export const Image = styled("img")`
  width: 100%;
  @media ${device.tablet} {
    width: 400px;
    margin-left: -50px;
  }
  @media ${device.mobileL} {
    width: 300px;
    margin-left: -50px;
  }
`;

export const Text = styled("div")`
  width: 40%;
  p {
    padding-left: 60px;
  }
  @media ${device.tablet} {
    width: 100%;
    text-align: center;
    p {
      padding-left: 0;
    }
  }
  @media (max-width: 536px) {
    width: 90%;
    margin: 0 auto;
  }
`;

export const Wrapper = styled("div")`
  display: flex;
  margin-top: -200px;
  @media ${device.tablet} {
    display: block;
  }
`;
export const Drawing = styled(ArtistDrawingIcon)`
  width: 190%;
  @media ${device.tablet} {
    width: 210px;
  }
  @media ${device.mobileL} {
    width: 160px;
    margin-top: -120px;
  }
`;

export const ImageWrapper = styled("div")`
  width: 60%;
`;

export const DrawingWrapper = styled("div")`
  width: 30%;
  margin: 0 auto 100px;
  position: relative;
  @media ${device.tablet} {
    margin: 0;
    width: fit-content;
    left: 200px;
  }
  @media ${device.mobileL} {
    left: 100px;
  }
`;

export const RedFlowerWrapper = styled("div")`
  width: 60%;
  text-align: center;
  margin: 0 auto;
`;

export const ButtonText = styled("div")`
  font-size: 24px;
  font-weight: 800;
  padding-top: 50px;
  text-align: center;
  color: white;
`;

export const BlackCircleButtonText = styled("div")`
  font-size: 24px;
  font-weight: 800;
  text-align: center;
  padding-top: 26px;
  color: white;
`;

export const FlowerWrapper = styled("div")`
  height: fit-content;
  a {
    text-decoration: none;
  }
`;

export const FlowersWrapper = styled("div")`
  display: flex;
  overflow: hidden;
  @media (max-width: 1110px) {
    margin-left: -200px;
  }
  @media ${device.tablet} {
    margin-left: 80px;
  }
  @media (max-width: 536px) {
    display: none
  }

`;


export const FlowersWrapperMobile = styled('div')`
  display: none;

  @media (max-width: 536px) {
    display: flex;
    overflow: hidden;
  }
  @media ${device.mobileL} {
    margin-left: -14px;
  }
`;