import styled from "styled-components";
import { device } from "../../constants/sizes";
import { ReactComponent as MainDrawingIcon } from "../Icons/MainDrawingIcon.svg";

export const Section = styled("div")`
  font-family: "Archivo";
  background: #ffd9d9;
  height: 1600px;
  @media ${device.laptopL} {
    height: 1000px;
  }
  @media ${device.laptop} {
    height: 720px;
  }
  @media ${device.tablet} {
    height: 560px;
  }
  @media ${device.mobileL} {
    height: 800px;
  }
  @media (max-width: 300px) {
    width: 300px;
  }
`;

export const LinksWrapper = styled("div")`
  display: flex;
  @media ${device.mobileL} {
    justify-content: space-between;
  }
`;
export const Links = styled("div")``;

export const Link = styled("div")`
  padding: 20px 0 10px 40px;
  cursor: pointer;
  a {
    text-decoration: none;
    color: black;
  }
`;
export const Menu = styled("div")`
  display: flex;
  width: 80%;
  justify-content: center;
  padding-top: 20px;
  @media ${device.mobileL} {
    display: none;
  }
`;
export const MenuItem = styled("div")`
  padding: 0 20px;
  cursor: pointer;
  @media ${device.tablet} {
    padding: 0 10px;
  }
`;

export const ContactWrapper = styled("div")`
  display: flex;
  justify-content: end;
  width: 80%;
  cursor: pointer;
  @media ${device.mobileL} {
    display: none;
  }
`;

export const ContactButton = styled("div")`
  width: 320px;
  height: 120px;
  background: black;
  border-radius: 50%;
  transform: rotate(-15deg);
  z-index: 2;
  @media ${device.tablet} {
    width: 220px;
    height: 80px;
  }
  @media ${device.mobileL} {
    width: 120px;
    height: 50px;
  }
`;

export const ContactButtonText = styled("div")`
  margin-top: 20px;
  font-size: 64px;
  font-weight: 800;
  text-align: center;
  color: white;
  transform: rotate(-3deg);
  a {
    color: white;
    text-decoration: none;
  }
  @media ${device.tablet} {
    margin-top: 16px;
    font-size: 40px;
  }
  @media ${device.mobileL} {
    margin-top: 12px;
    font-size: 20px;
  }
`;

export const LogoImage = styled("img")`
  width: 110%;
  @media ${device.mobileL} {
    width: 280px;
  }
`;

export const ImageWrapper = styled("div")`
  margin: 100px auto;
  text-align: center;
  width: 50%;
  @media ${device.laptop} {
    margin: 30px auto;
  }
  @media ${device.tablet} {
    margin: 20px auto;
  }
  @media ${device.mobileL} {
    position: absolute;
    top: 180px;
    width: 100%;
  }
`;

export const MainDrawing = styled(MainDrawingIcon)`
  position: absolute;
  top: 100px;
  width: 90%;
  .path {
    stroke-dasharray: 3000; /* Increase this value if needed */
    stroke-dashoffset: 5000; /* Increase this value if needed */
  }

  .path-animation {
    animation: dash 10s linear forwards; /* Adjust the duration as needed */
  }

  @keyframes dash {
    to {
      stroke-dashoffset: 0;
    }
  }
  @media ${device.mobileL} {
    margin-left: -50px;
    width: 490px;
    position: static;
  }
`;

export const MainDrawingContainer = styled("div")`
  height: 600px;
  @media ${device.mobileL} {
    margin-top: 40px;
    overflow: hidden;
    height: auto;
  }
`;

export const MobileMenu = styled("div")`
    position: absolute;
    right: 10px;
    top: 18px;
  @media (min-width: 431px) {
    display: none;
  }
`;

export const MobileMenuItem = styled("div")`
padding-bottom: 5px;
`;

export const MobileMenuIconWrapper = styled("div")`
  position: absolute;
  right: 20px;
  top: 20px;
  @media (min-width: 431px) {
    display: none;
  }
`;

export const ContactButtonMobile = styled("div")`
  width: 265px;
  height: 106px;
  margin: 0 auto;
  background: black;
  border-radius: 50%;
  transform: rotate(-15deg);
  @media (min-width: 431px) {
    display: none;
  }
`;

export const ContactButtonTextMobile = styled("div")`
  margin-top: 100px;
  padding-top: 30px;
  font-size: 40px;
  font-weight: 800;
  text-align: center;
  transform: rotate(-3deg);
  a {
    color: white;
    text-decoration: none;
  }
  @media (min-width: 431px) {
    display: none;
  }
`;
