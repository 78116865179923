import styled from "styled-components";
import { device } from "../../constants/sizes";
import { ReactComponent as FooterDrawingIcon } from "../Icons/FooterDrawingIcon.svg";
import { ReactComponent as ArrowUpIcon } from "../Icons/ArrowUpIcon.svg";


export const Section = styled("div")`
  font-family: "Archivo";
  height: 500px;
  background-color: #ce0808;
  @media ${device.tablet} {
    height: auto;
    padding-bottom: 20px;
  }
  @media (max-width: 300px) {
    width: 300px;
  }
`;

export const TopWrapper = styled("div")`
  display: flex;
  justify-content: center;
  @media ${device.tablet} {
    display: block;
    text-align: center;
  }
`;

export const BottomWrapper = styled("div")`
  display: flex;
  @media ${device.tablet} {
    display: block;
    text-align: center;
    padding-top: 20px;
  }
`;

export const Image = styled("img")`
  width: 340px;
  padding: 50px;
  @media ${device.laptop} {
    width: 240px;
  }
  @media ${device.tablet} {
    width: 200px;
  }
`;

export const Drawing = styled(FooterDrawingIcon)`
  width: 470px;
  position: relative;
  top: 50px;
  left: -50px;
  @media ${device.laptop} {
    width: 270px;
  }
  @media ${device.tablet} {
    display: none;
  }
`;

export const Links = styled("div")`
  width: 10%;
  @media ${device.tablet} {
    width: auto;
  }
`;

export const ImageWrapper = styled("div")``;

export const DrawingWrapper = styled("div")``;

export const Link = styled("div")`
  padding-top: 20px;
  color: white;
  cursor: pointer;
  a {
    text-decoration: none;
    color: white;
  }
`;

export const Button = styled("div")`
  position: relative;
  left: 280px;
  width: 280px;
  height: 110px;
  border-radius: 50%;
  background-color: black;
  @media ${device.laptop} {
    width: 180px;
    height: 80px;
    top: -30px;
    left: 180px;
  }
  @media ${device.tablet} {
    display: none;
  }
`;

export const Contact = styled("div")`

`;
export const ContactItem = styled("div")`
padding-right: 20px;
  color: white;
  a {
    text-decoration: none;
    color: white;
  }
  @media ${device.tablet} {
    padding-top: 20px;
  }
`;

export const BackButton = styled("div")`
  margin: -30px 40px 0px 40px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: #ffd9d9;
  @media ${device.tablet} {
    display: none;
  }
`;

export const Creators = styled("div")`
  padding: 0 20px;
  color: white;
  a {
    color: white;
  }
  @media ${device.tablet} {
    padding-bottom: 20px;
  }
`;
export const Rights = styled("div")`
  padding: 0 20px;
  color: white;
`;

export const ArrowUp = styled(ArrowUpIcon)``;

export const ButtonText = styled("div")`
  padding-top: 24px;
  font-weight: 800;
  font-size: 54px;
  text-align: center;
  @media ${device.laptop} {
    font-size: 40px;
  }
  a {
    color: white;
    text-decoration: none;
  }
`;

export const ButtonMobile = styled("div")`
  width: 180px;
  height: 80px;
  margin: 0 auto;
  border-radius: 50%;
  background-color: black;
  @media (min-width: 768px) {
    display: none;
  }
`;

export const BackButtonMobile = styled("div")`
  margin: 0 auto 20px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: #ffd9d9;
  @media (min-width: 768px) {
    display: none;
  }
`;
