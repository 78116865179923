import styled from "styled-components";
import { device } from "../../constants/sizes";


export const Section = styled("div")`
  font-family: "Archivo";
  width: 90%;
  margin: 0 auto;
`;

export const Title = styled("div")`
  font-weight: 800;
  font-size: 64px;
  margin: 100px 0 60px;
`;

export const Wrapper = styled("div")`
  display: flex;
  margin-top: 40px;
  @media ${device.tablet} {
    display: block;
  }
`;

export const ImageWrapper = styled("div")`
  text-align: center;
`;

export const Image = styled("img")`
  width: 90%;
`;

export const RightText = styled("div")`
  width: 60%;
  margin: 0 auto;
  font-weight: 800;
  font-size: 24px;
  text-align: left;
  @media ${device.tablet} {
    display: none;
  }
`;

export const RightDescriptionText = styled("div")`
  width: 60%;
  margin: 0 auto;
  padding: 20px 0 100px;
  text-align: left;
  @media ${device.tablet} {
    display: none;
  }
`;

export const RightTextMobile = styled("div")`
  width: 60%;
  margin: 0 auto;
  font-weight: 800;
  font-size: 24px;
  text-align: right;
  @media (min-width: 768px) {
    display: none;
  }
`;

export const RightDescriptionTextMobile = styled("div")`
  width: 60%;
  margin: 0 auto;
  padding: 20px 0 100px;
  text-align: right;
  @media ${device.tablet} {
    padding: 20px 0;
  }
  @media (min-width: 768px) {
    display: none;
  }
`;

export const LeftText = styled("div")`
  width: 60%;
  margin: 0 auto;
  padding-top: 20px;
  font-weight: 800;
  font-size: 24px;
  text-align: right;
  @media ${device.tablet} {
    display: none;
  }
`;

export const LeftDescriptionText = styled("div")`
  width: 60%;
  margin: 0 auto;
  padding-top: 20px;
  text-align: right;
  @media ${device.tablet} {
    display: none;
  }
`;

export const LeftTextMobile = styled("div")`
  width: 60%;
  margin: 0 auto;
  padding-top: 20px;
  font-weight: 800;
  font-size: 24px;
  text-align: left;
  @media (min-width: 768px) {
    display: none;
  }
`;

export const LeftDescriptionTextMobile = styled("div")`
  width: 60%;
  margin: 0 auto;
  padding: 20px 0;
  text-align: left;
  @media (min-width: 768px) {
    display: none;
  }
`;
