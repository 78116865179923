import styled from "styled-components";
import { device } from "../../constants/sizes";

export const Section = styled("div")`
  font-family: "Archivo";
  width: 90%;
  margin: 0 auto;
`;

export const Title = styled("div")`
  font-weight: 800;
  font-size: 64px;
  margin: 100px 0 60px;
`;

export const ImageWrapper = styled("div")``;

export const Image = styled("img")`
  width: 100%;
`;

export const ItemWrapper = styled("div")``;

export const Wrapper = styled("div")`
  display: flex;
  justify-content: space-around;
  & > ${ItemWrapper}:nth-child(2) {
    padding: 80px; /* Add your desired padding value */
  }
  @media ${device.tablet} {
    display: block;
    & > ${ItemWrapper}:nth-child(2) {
      padding: 0;
    }
  }
`;

export const Text = styled("div")`
  text-align: center;
  font-weight: 800;
  font-size: 24px;
  span {
    display: block;
    font-size: 16px;
    font-weight: 400;
    padding-top: 20px;
  }
  @media ${device.tablet} {
    span {
      padding-bottom: 20px;
    }
  }
`;
