import React, { useState } from "react";
import {
  Section,
  TopWrapper,
  BottomWrapper,
  Button,
  ButtonText,
  Link,
  Links,
  Contact,
  ContactItem,
  BackButton,
  Image,
  ImageWrapper,
  Drawing,
  DrawingWrapper,
  ArrowUp,
  Creators,
  Rights,
  ButtonMobile,
  BackButtonMobile,
} from "./Footer.styled";
import Logo from "../../media/footerLogo.png";

export const Footer = () => {
  const [showScrollButton, setShowScrollButton] = useState(false);

  const handleBackButtonClick = () => {
    window.scrollTo({ top: 0 });
    setShowScrollButton(true);
  };

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0 });
  };

  return (
    <Section id="footer">
      <TopWrapper>
        <ImageWrapper>
          <Image src={Logo} />
        </ImageWrapper>
        <DrawingWrapper>
          <BackButtonMobile onClick={handleBackButtonClick}>
            <ArrowUp />
          </BackButtonMobile>
          <ButtonMobile>
            <ButtonText>
              <a href="mailto:anna.liaver@gmail.com">Contact</a>
            </ButtonText>
          </ButtonMobile>
          <Drawing />

          <Button>
            <ButtonText>
              <a href="mailto:anna.liaver@gmail.com">Contact</a>
            </ButtonText>
          </Button>
        </DrawingWrapper>
        <Links>
          <Link>
            <a href="https://www.instagram.com/liaveranastasiia/">Instagram</a>
          </Link>
          <Link>
            <a href="https://www.tiktok.com/@liaver.a">Tiktok</a>
          </Link>
        </Links>
      </TopWrapper>
      <BottomWrapper>
        {showScrollButton && (
          <BackButton onClick={handleScrollToTop}>
            <ArrowUp />
          </BackButton>
        )}
        <Creators>
          Created by <a href="https://webaste.io/">Webaste</a>
        </Creators>
        <Rights>All rights reserved © Anastasiia Liaver 2024</Rights>
        <ContactItem>
          <a href="mailto:anna.liaver@gmail.com">anna.liaver@gmail.com</a>
        </ContactItem>
      </BottomWrapper>
    </Section>
  );
};
