import {
  Section,
  Text,
  Title,
  Wrapper,
  ItemWrapper,
  ImageWrapper,
  Image,
} from "./Merch.styled";
import Tee1 from "../../media/tee1.png";
import Tee2 from "../../media/tee2.png";

export const Merch = () => {
  return (
    <Section id="merch">
      <Title>Merch</Title>
      <Wrapper>
        <ItemWrapper>
          <ImageWrapper>
            <Image loading="lazy" src={Tee1} />
          </ImageWrapper>
          <Text>
            Imielin<span>onesize</span>
          </Text>
        </ItemWrapper>
        <ItemWrapper>
          <ImageWrapper>
            <Image loading="lazy" src={Tee2} />
          </ImageWrapper>
          <Text>
            Beefeater london dry & Mezcal<span>onesize</span>
          </Text>
        </ItemWrapper>
      </Wrapper>
    </Section>
  );
};
