import {
  Section,
  Text,
  Title,
  Wrapper,
  ImageWrapper,
  Drawing,
  DrawingWrapper,
  FlowerWrapper,
  FlowersWrapper,
  RedCircle,
  RedFlower,
  RedFlowerWrapper,
  BlackCircle,
  BlackFlower,
  BlackCircleButtonText,
  ButtonText,
  Image,
  FlowersWrapperMobile
} from "./Artist.styled";
import Portrait1 from "../../media/portrait1.png";

export const Artist = () => {
  return (
    <Section id="artist">
      <Title>Artist</Title>
      <DrawingWrapper>
        <Drawing />
      </DrawingWrapper>
      <Wrapper>
        <ImageWrapper>
          <Image loading="lazy" src={Portrait1} />
        </ImageWrapper>
        <Text>
          <p>{`From a young age, Anastasiia's passion for painting and drawing has been an integral part of her life. Hailing from Uzhhorod,
            Anastasiia honed her artistic skills by attending art school, where she delved deeper into various mediums, techniques,
            and artistic principles. These formative years laid the foundation for her artistic growth and development.`}</p>
          <p>{`Despite her deep-rooted love for the arts, Anastasiia pursued 
            a different academic path and obtained her bachelor's and master's degrees in finance. While the world of finance provided her
            with a unique perspective on life, it was in art that she found true fulfilment and authenticity.`}</p>
          <FlowersWrapper>
            <FlowerWrapper>
              <a href="https://www.instagram.com/liaveranastasiia/">
                <RedFlower />
                <BlackCircle>
                  <BlackCircleButtonText>Insta</BlackCircleButtonText>
                </BlackCircle>
              </a>
            </FlowerWrapper>
            <FlowerWrapper>
              <a href="https://www.tiktok.com/@liaver.a">
                <BlackFlower />
                <RedCircle>
                  <ButtonText>TikTok</ButtonText>
                </RedCircle>
              </a>
            </FlowerWrapper>
          </FlowersWrapper>
        </Text>
        <FlowersWrapperMobile>
            <FlowerWrapper>
              <a href="https://www.instagram.com/liaveranastasiia/">
                <RedFlower />
                <BlackCircle>
                  <BlackCircleButtonText>Insta</BlackCircleButtonText>
                </BlackCircle>
              </a>
            </FlowerWrapper>
            <FlowerWrapper>
              <a href="https://www.tiktok.com/@liaver.a">
                <BlackFlower />
                <RedCircle>
                  <ButtonText>TikTok</ButtonText>
                </RedCircle>
              </a>
            </FlowerWrapper>
          </FlowersWrapperMobile>
      </Wrapper>
      <RedFlowerWrapper></RedFlowerWrapper>
    </Section>
  );
};
