import {
  Section,
  Title,
  Wrapper,
  ImageWrapper,
  Image,
  LeftText,
  LeftDescriptionText,
  RightText,
  RightDescriptionText,
  RightTextMobile,
  RightDescriptionTextMobile,
  LeftTextMobile,
  LeftDescriptionTextMobile,
} from "./Prints.styled";
import Print1 from "../../media/print1.png";
import Print2 from "../../media/print2.png";
import Print3 from "../../media/print3.png";
import Print4 from "../../media/print4.png";

export const Prints = () => {
  return (
    <Section id="prints">
      <Title>Prints</Title>
      <Wrapper>
        <ImageWrapper>
          <Image loading="lazy" src={Print1}></Image>
          <LeftText>"What a Mess!”</LeftText>
          <LeftDescriptionText>
            {
              "2022 Archival gallery quality prints on a thick paper, size 50×70 (format B2) available for sale. Hand signed and numbered."
            }
          </LeftDescriptionText>
          <LeftTextMobile>"Mirror”</LeftTextMobile>
          <LeftDescriptionTextMobile>
            {
              "2022. Archival gallery quality prints on a thick paper, size 50×70 (format B2) available for sale.50 copies, limited edition, hand signed and numbered."
            }
          </LeftDescriptionTextMobile>
        </ImageWrapper>
        <ImageWrapper>
          <RightText>"Mirror”</RightText>
          <RightDescriptionText>
            {
              "2022. Archival gallery quality prints on a thick paper, size 50×70 (format B2) available for sale.50 copies, limited edition, hand signed and numbered."
            }
          </RightDescriptionText>
          <Image loading="lazy" src={Print2}></Image>
          <RightTextMobile>"What a Mess!”</RightTextMobile>
          <RightDescriptionTextMobile>
            {
              "2022 Archival gallery quality prints on a thick paper, size 50×70 (format B2) available for sale. Hand signed and numbered."
            }
          </RightDescriptionTextMobile>
        </ImageWrapper>
      </Wrapper>
      <Wrapper>
        <ImageWrapper>
          <Image loading="lazy" src={Print3}></Image>
          <LeftTextMobile>"Black. Red. Blue”</LeftTextMobile>
          <LeftDescriptionTextMobile>
            {
              "2022 Archival gallery quality prints on a thick paper, size 50×70 (format B2) available for sale. Hand signed and numbered."
            }
          </LeftDescriptionTextMobile>
        </ImageWrapper>
        <ImageWrapper>
          <RightText>"Black. Red. Blue”</RightText>
          <RightDescriptionText>
            {
              "2022 Archival gallery quality prints on a thick paper, size 50×70 (format B2) available for sale. Hand signed and numbered."
            }
          </RightDescriptionText>
          <Image loading="lazy" src={Print4}></Image>
          <LeftText>"Red means love, black means sorrow”</LeftText>
          <LeftDescriptionText>
            {
              "2022 Archival gallery quality prints on a thick paper, size 50×70 (format B2) available for sale. Hand signed and numbered. "
            }
          </LeftDescriptionText>
          <RightTextMobile>
            "Red means love, black means sorrow”
          </RightTextMobile>
          <RightDescriptionTextMobile>
            {
              "2022 Archival gallery quality prints on a thick paper, size 50×70 (format B2) available for sale.Hand signed and numbered."
            }
          </RightDescriptionTextMobile>
        </ImageWrapper>
      </Wrapper>
    </Section>
  );
};
