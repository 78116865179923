import styled from "styled-components";
import { device } from "../../constants/sizes";
import { ReactComponent as ArrowLeftIcon } from "../Icons/ArrowLeftIcon.svg";
import { ReactComponent as ArrowRightIcon } from "../Icons/ArrowRightIcon.svg";

export const Section = styled("div")`
  font-family: "Archivo";
  width: 90%;
  margin: 0 auto;
`;

export const Title = styled("div")`
  font-weight: 800;
  font-size: 64px;
  margin: 100px 0 60px;
`;

export const ButtonContainer = styled("div")`
  display: flex;
  justify-content: end;
  margin-bottom: 20px;
  @media ${device.mobileL} {
    display: none;
  }
`;

export const ArrowLeft = styled(ArrowLeftIcon)`
  padding: 16px;
`;

export const ArrowRight = styled(ArrowRightIcon)`
  padding: 16px;
`;

export const Wrapper = styled("div")`
  display: flex;
  border: 2px solid black;
  border-radius: 30px;
  @media ${device.tablet} {
    display: block;
    height: 500px;
  }
`;

export const Image = styled("img")`
  max-height: 600px;
  @media ${device.laptopL} {
    max-height: 500px;
  }
  @media ${device.laptop} {
    max-height: 360px;
  }
  @media ${device.tablet} {
    max-width: 90%;
  }
`;

export const ImageWrapper = styled("div")`
  padding: 20px;
  opacity: 0;
  &.active {
    transition: opacity 0.5s ease-out;
    opacity: 1;
  }
`;

export const Button = styled("div")`
  background-color: #ce0808;
  width: 55px;
  height: 55px;
  border-radius: 50px;
  margin-right: 10px;
  cursor: pointer;
`;

export const ImageTitle = styled("div")`
  font-weight: 800;
  font-size: 24px;
`;

export const ImageDescription = styled("div")`
  padding-top: 20px;
`;

export const TextWrapper = styled("div")`
  padding-top: 20px;
  opacity: 0;
  &.active {
    transition: opacity 0.5s ease-out;
    opacity: 1;
  }
  @media ${device.tablet} {
    padding: 0 0 20px 20px;
  }
`;
