import { useEffect, useState } from "react";
import { Header } from "./components/Header/Header";
import { Artist } from "./components/Artist/Artist";
import { About } from "./components/About/About";
import { Merch } from "./components/Merch/Merch";
import { Footer } from "./components/Footer/Footer";
import { Prints } from "./components/Prints/Prints";
import { Slider } from "./components/Slider/Slider";

function App() {
  const [scrollReached, setScrollReached] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      let scrollHeight = 2500;
      if (window.innerWidth < 1024 && window.innerWidth >= 460) {
        scrollHeight = 3500;
      }
      if (window.innerWidth < 460) {
        scrollHeight = 5000;
      }
      if (window.scrollY >= scrollHeight) {
        setScrollReached(true);
      } else {
        setScrollReached(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClickScroll = (e) => {
    const getElementId = () => {
      switch (e.target.id) {
        case "menu-catalog":
          return "catalog";
        case "menu-artist":
          return "artist";
        case "menu-prints":
          return "prints";
        case "menu-about":
          return "about";
        case "menu-merch":
          return "merch";
        case "menu-footer":
          return "footer";
        default:
          return "";
      }
    };
    console.log(getElementId());

    const element = document.getElementById(getElementId(e.target.id));

    if (element) {
      const targetPosition = element.offsetTop; // Get the top position of the target element
      const startPosition = window.pageYOffset; // Get the current scroll position
      const distance = targetPosition - startPosition;
      window.scrollTo(0, startPosition + distance); // Update the scroll position
    }
  };

  return (
    <>
       <Header handleClickScroll={handleClickScroll} />
      <Slider />
      <Artist />
      <Prints />
      <About />
      <Merch />
      <Footer handleClickScroll={handleClickScroll} />
    </>
  );
}

export default App;
