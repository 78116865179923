import { useState } from "react";
import Catalog1 from "../../media/catalog1.png";
import Catalog2 from "../../media/catalog2.png";
import Catalog3 from "../../media/catalog3.png";
import Catalog4 from "../../media/catalog4.png";
import Catalog5 from "../../media/catalog5.png";
import Catalog6 from "../../media/catalog6.png";
import Catalog7 from "../../media/catalog7.png";
import Catalog8 from "../../media/catalog8.png";
import Catalog9 from "../../media/catalog9.png";
import Catalog10 from "../../media/catalog10.png";
import Catalog11 from "../../media/catalog11.png";
import Catalog12 from "../../media/catalog12.png";


import {
  Section,
  Title,
  Button,
  ButtonContainer,
  Image,
  ImageDescription,
  ImageTitle,
  ImageWrapper,
  TextWrapper,
  ArrowLeft,
  ArrowRight,
  Wrapper,
} from "./Slider.styled";

export const Slider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [active, setActive] = useState("active");

  const data = [
    {
      id: 0,
      src: Catalog1,
      title: `“The Sacrifice”`,
      description: "2023 | 70X100 acrylic on paper, ink",
    },
    {
      id: 1,
      src: Catalog2,
      title: `“Nat - Mur”`,
      description: "2021 | 50X70 watercolour on paper",
    },
    {
      id: 4,
      src: Catalog4,
      title: `“Soulmates”`,
      description: "2023 | 70x100 acrylic on paper",
    },
    {
      id: 6,
      src: Catalog6,
      title: `“Sisters”`,
      description: "2023 | 70x100 acrylic and tempera on paper",
    },
    {
      id: 7,
      src: Catalog7,
      title: `“When in Vienna”`,
      description: "2017 | oil on canva",
    },
    {
      id: 8,
      src: Catalog8,
      title: `“Nostalgia”`,
      description: "2023 70x100 acrylic and tempera on paper",
    },
    {
      id: 10,
      src: Catalog10,
      title: `“Ashes and Diamonds”`,
      description: "2022 | 70X100 ink, acrylic on paper",
    },
    {
      id: 11,
      src: Catalog11,
      title: `“Pick up pieces”`,
      description: "2022 | 50x70 acrylic on paper, ink",
    },
    {
      id: 12,
      src: Catalog12,
      title: `“Dacza. Childhood”`,
      description: "2022 | 70X100 ink, acrylic on paper",
    },
  ];

  const handleClickLeft = () => {
    setActive("");
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? data.length - 1 : prevIndex - 1,
    );
    setTimeout(() => {
      setActive("active");
    }, 500);
  };

  const handleClickRight = () => {
    setActive("");
    setCurrentIndex((prevIndex) =>
      prevIndex === data.length - 1 ? 0 : prevIndex + 1,
    );
    setTimeout(() => {
      setActive("active");
    }, 500);
  };

  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe) {
      setActive("");
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? data.length - 1 : prevIndex - 1,
      );
      setTimeout(() => {
        setActive("active");
      }, 500);
    }
    if (isRightSwipe) {
      setActive("");
      setCurrentIndex((prevIndex) =>
        prevIndex === data.length - 1 ? 0 : prevIndex + 1,
      );
      setTimeout(() => {
        setActive("active");
      }, 500);
    }
  };
  return (
    <Section id="catalog">
      <Title>Catalog</Title>
      <ButtonContainer>
        <div>
          <Button onClick={handleClickLeft}>
            <ArrowLeft />
          </Button>
        </div>
        <div>
          <Button onClick={handleClickRight}>
            <ArrowRight />
          </Button>
        </div>
      </ButtonContainer>
      <Wrapper
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        <ImageWrapper className={active ? "active" : ""}>
          <Image loading="lazy" src={data[currentIndex].src} alt="service-mobile" />
        </ImageWrapper>

        <TextWrapper className={active ? "active" : ""}>
          <ImageTitle>{data[currentIndex].title}</ImageTitle>
          <ImageDescription>{data[currentIndex].description}</ImageDescription>
        </TextWrapper>
      </Wrapper>
      {/* <div className={"service-slider-info-mobile-dots"}>
        <div
          className={`service-slider-info-mobile-dots-dot ${
            currentIndex === 0 ? "active" : ""
          }`}
        ></div>
        <div
          className={`service-slider-info-mobile-dots-dot ${
            currentIndex === 2 ? "active" : ""
          }`}
        ></div>
        <div
          className={`service-slider-info-mobile-dots-dot ${
            currentIndex === 1 ? "active" : ""
          }`}
        ></div>
      </div> */}
    </Section>
  );
};
