import {
  Section,
  Text,
  Title,
  Wrapper,
  Image,
  ImageWrapper,
  Link,
  LinkMobile,
  DrawingWrapper,
  Drawing,
  Button,
  ButtonMobile,
  ButtonText,
  ButtonTextMobile,
  MediaWrapper,
} from "./About.styled";
import Portrait2 from "../../media/portrait2.png";

export const About = () => {
  return (
    <Section id="about">
      <Title>About</Title>
      <Wrapper>
        <Text>
          {`Art is my medium for expressing my emotions, and while I often employ the body print technique, I don't confine myself to it.
            Sharing my art with an audience has always been challenging for me, as it feels deeply personal and intimate.
            It's as if by witnessing it, you would unravel all my innermost secrets.
            However, I have reached a point where I finally feel prepared to unveil my work.`}
        </Text>
        <MediaWrapper>
          <Link href="https://www.tiktok.com/@liaver.a">
            <Button>
              <ButtonText>More</ButtonText>
            </Button>
          </Link>
          <ImageWrapper>
            <Image loading="lazy" src={Portrait2} />
          </ImageWrapper>
          <DrawingWrapper>
            <Drawing />
          </DrawingWrapper>
          <LinkMobile href="https://www.tiktok.com/@liaver.a">
            <ButtonMobile>
              <ButtonTextMobile>More</ButtonTextMobile>
            </ButtonMobile>
          </LinkMobile>
        </MediaWrapper>
      </Wrapper>
    </Section>
  );
};
