import { useEffect, useRef, useState } from "react";
import {
  ImageWrapper,
  Section,
  Link,
  Links,
  Menu,
  MenuItem,
  ContactWrapper,
  ContactButton,
  ContactButtonText,
  LinksWrapper,
  MainDrawing,
  MainDrawingContainer,
  LogoImage,
  MobileMenu,
  MobileMenuItem,
  MobileMenuIconWrapper,
} from "./Header.styled";
import Logo from "../../media/logo.png";
import { ReactComponent as MobileMenuIcon } from "../Icons/MobileMenuIcon.svg";

function useClickOutside(containerRef, callback) {
  const callbackRef = useRef();

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        callbackRef.current();
      }
    }

    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [containerRef]);
}

export const Header = ({ handleClickScroll }) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef(null);

  const toggleMenu = (e) => {
    setIsOpen(true);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  useClickOutside(containerRef, closeMenu);

  useEffect(() => {
    const path = document.querySelector(".path");
    const pathLength = path.getTotalLength();

    // Set the path length to the stroke-dasharray and stroke-dashoffset properties
    path.style.strokeDasharray = pathLength;
    path.style.strokeDashoffset = pathLength;
    // Add the animation class to start the drawing effect
    path.classList.add("path-animation");
  }, []);

  return (
    <Section id="header">
      <LinksWrapper>
        <Links>
          <Link>
            <a href="https://www.instagram.com/liaveranastasiia/">Instagram</a>
          </Link>
          <Link>
            <a href="https://www.tiktok.com/@liaver.a">Tiktok</a>
          </Link>
        </Links>
        <Menu>
          <MenuItem id="menu-catalog" onClick={handleClickScroll}>
            Catalog
          </MenuItem>
          <MenuItem id="menu-artist" onClick={handleClickScroll}>
            Artist
          </MenuItem>
          <MenuItem id="menu-prints" onClick={handleClickScroll}>
            Prints
          </MenuItem>
          <MenuItem id="menu-about" onClick={handleClickScroll}>
            About
          </MenuItem>
          <MenuItem id="menu-merch" onClick={handleClickScroll}>
            Merch
          </MenuItem>
        </Menu>
        {!isOpen && (
          <MobileMenuIconWrapper onClick={toggleMenu} onTouchStart={toggleMenu}>
            <MobileMenuIcon />
          </MobileMenuIconWrapper>
        )}
        {isOpen && (
          <MobileMenu ref={containerRef}>
            <MobileMenuItem
              id="menu-catalog"
              className="header-menu-item"
              onTouchStart={handleClickScroll}
            >
              Catalog
            </MobileMenuItem>
            <MobileMenuItem
              id="menu-artist"
              className="header-menu-item"
              onTouchStart={handleClickScroll}
            >
              Artist
            </MobileMenuItem>
            <MobileMenuItem
              id="menu-prints"
              className="header-menu-item"
              onTouchStart={handleClickScroll}
            >
              Prints
            </MobileMenuItem>
            <MobileMenuItem
              id="menu-about"
              className="header-menu-item"
              onTouchStart={handleClickScroll}
            >
              About
            </MobileMenuItem>
            <MobileMenuItem
              id="menu-merch"
              className="header-menu-item"
              onTouchStart={handleClickScroll}
            >
              Merch
            </MobileMenuItem>
          </MobileMenu>
        )}
      </LinksWrapper>
      <ContactWrapper>
        <ContactButton>
          <ContactButtonText>
            <a href="mailto:anna.liaver@gmail.com">Contact</a>
          </ContactButtonText>
        </ContactButton>
      </ContactWrapper>
      <MainDrawingContainer>
        <MainDrawing />
        <ImageWrapper>
          <LogoImage loading="lazy" src={Logo} />
        </ImageWrapper>
      </MainDrawingContainer>
    </Section>
  );
};
