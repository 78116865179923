import styled from "styled-components";
import { device } from "../../constants/sizes";
import { ReactComponent as AboutDrawingIcon } from "../Icons/AboutDrawingIcon.svg";

export const Section = styled("div")`
  font-family: "Archivo";
  width: 90%;
  height: 860px;
  margin: 0 auto;
  @media ${device.tablet} {
    width: auto;
  }
`;

export const Title = styled("div")`
  font-weight: 800;
  font-size: 64px;
  margin: 100px auto 60px;
  @media ${device.tablet} {
    width: 90%;
  }
`;

export const Text = styled("div")`
  width: 54%;
  @media ${device.laptop} {
    width: 100%;
    text-align: center;
  }
  @media ${device.tablet} {
    width: 90%;
    margin: 0 auto;
  }
`;

export const DrawingWrapper = styled("div")`
  @media ${device.laptop} {
    height: 0;
  }
`;
export const Drawing = styled(AboutDrawingIcon)`
  width: 700px;
  position: relative;
  top: -620px;
`;

export const ImageWrapper = styled("div")`
  width: 60%;
`;

export const Image = styled("img")`
  width: 400px;
  position: relative;
  left: 150px;
  z-index: 1;
`;

export const Button = styled("div")`
  position: relative;
  top: 100px;
  left: -170px;

  background-color: #ce0808;
  width: 320px;
  height: 120px;
  border-radius: 50%;
  text-align: center;
  z-index: 2;
  cursor: pointer;
  @media ${device.laptop} {
    display: none;
  }
`;

export const ButtonText = styled("div")`
  color: white;
  font-weight: 800;
  font-size: 64px;
  padding-top: 20px;
`;

export const Wrapper = styled("div")`
  display: flex;
  @media ${device.laptop} {
    display: block;
  }
  @media ${device.tablet} {
    overflow: hidden;
  }
`;

export const MediaWrapper = styled("div")`
  margin-top: 40px;

  @media (max-width: 1140px) {
    margin-top: 140px;
  }
  @media ${device.tablet} {
    margin-top: 100px;
  }
`;

export const Link = styled("a")`
  text-decoration: none;
  @media ${device.tablet} {
    display: none;
  }
`;

export const LinkMobile = styled("a")`
  text-decoration: none;
`;

export const ButtonMobile = styled("div")`
  position: relative;
  background-color: #ce0808;
  width: 225px;
  height: 90px;
  border-radius: 50%;
  text-align: center;
  z-index: 2;
  cursor: pointer;
  @media (min-width: 1025px) {
    display: none;
  }
`;

export const ButtonTextMobile = styled("div")`
  color: white;
  font-weight: 800;
  font-size: 48px;
  padding-top: 16px;
`;
